import { notUndefined } from '@utils/common'
import React, { useEffect, useMemo } from 'react'
import { getPlacementProductData } from './helpers'
import { TGridOfProducts } from './types'
import { SuggestedProductTile } from '../SuggestedProductTile'
import { hclDTO } from '@utils/productNew'
import { StyledGridOfProductsContainer } from './GridOfProductsStyle'
import { usePageType } from '@foundation/hooks/usePageType'
import { mapMonetateProductsHome, trackProducts } from '@foundation/monetate/lib'
import { listenMonetateQ } from '@foundation/monetate/useMonetate'

const GridOfProducts: React.FC<TGridOfProducts> = ({ placement, lazy }) => {
  const productData = useMemo(() => getPlacementProductData(placement), [placement])
  const monetateLoaded = listenMonetateQ()

  if (!productData.length) return null

  const products = productData?.map(hclDTO).filter(notUndefined)
  const pageType = usePageType()

  useEffect(() => {
    if (pageType.pageType && products.length > 0 && monetateLoaded) {
      trackProducts(mapMonetateProductsHome(products), pageType.pageType)
    }
  }, [pageType?.pageType, products.length, monetateLoaded])

  return (
    <StyledGridOfProductsContainer>
      {products.map((teaser, index) => (
        <SuggestedProductTile product={teaser} tileIndex={index} lazy={lazy} key={teaser.productId} />
      ))}
    </StyledGridOfProductsContainer>
  )
}

export default GridOfProducts
