import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMProductList } from '@typesApp/cmsPlacement/CMProductList'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import { IProductType } from '../types'
import { THclProductRes } from '@utils/productNew'

export const getPlacementProductData = (placement: IPlacement | ICMCollection | ICMProductList): THclProductRes[] => {
  if (!('items' in placement) && placement.type !== 'CMCollection') {
    return placement.productData || []
  }
  const items: IPlacementItem[] = ('items' in placement ? placement.items : placement.teasableItems) || []
  return (items.filter(item => ['CMProductTeaser', 'CMExternalProduct'].includes(item.type)) as IProductType).map(
    x => x.productData
  )
}
